.preferencesTxF {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 130px;
    width: 300px;
}

.preferencesTxF label {
    color: black; 
}

.scPrice {
    margin-left: 15% !important;
}

.examPrice {
    margin-left: 15% !important;
}

.preferencesBtns {
    margin-bottom: 15%;
    height: 50px;
    color: white !important;
    font-size: 1.7em !important;
    font-weight: bold;
    border: 2px solid #00C7FF !important;
    border-radius: 9999px !important;
    background-color: #1D1D1D !important;
}

.preferencesBtns:hover {
    background-color: #00C7FF !important;
    color: black !important;
    font-weight: bold !important;
}

.MuiInputBase-input {
    font-size: 2em !important;
}

.preferencesTitle {
    margin-top: 3% !important;
    margin-bottom: 5% !important;
    font-size: 6em;
    color: black; 
    font-size: 6em;
}

#preferencesBottom {
    background-color: #D9D9D9;
    height: 50vh;
}



@media (max-width: 1399px) {

    .preferencesFirstRow {
        margin-bottom: 3% !important;
    }

    .preferencesBtns {
        margin-top: -5% !important;
    }

    .preferencesTitle {
        font-size: 6em !important;
    }
  
}