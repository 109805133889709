@font-face {
  font-family: 'Impact';
  src: './css/fonts/impact.ttf';
}

:root {
  --blanco: #ffffff;
  --gris1: #edf2f6;
  --gris2: #2f3848;
  --gris3: #1a202d;
  --negro: #000000;
  --headingFont:'Raleway', sans-serif;
  --textFont:  'Roboto', sans-serif;
}

*, *:before, *:after {
  box-sizing: inherit;
} 
html {
  margin: 0;
  box-sizing: border-box;
  height: 100%;
  font-size: 62.5%; 
}
body {
  margin: 0;
  height: 100%;
  background-color: #146c7e;
  font-size: 1.6rem;
  font-family: var(--textFont);
  
}

#root {
  height: 100%;
}

td {
  border: solid 1px black;
}

.mainContainer {
  min-height: 100vh;
}

.content {
  min-height: 100vh;
  background-color: #DFDFDF;
  position: relative;
}


  .MuiTableHead-root {
    width: 300px !important;
  }









  .navbarItems {
    font-weight: bold;
  }
  
  .navbarItemsBtns {
    background: none;
  }
  
  #greetBar {
    display: none;
  }
  
  .footer {
    height: 60px;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  
  .footerBrand {
    color: green;
    font-weight: bold;
  }
  
  .rowSelected {
    background-color: #2FD9FF !important;
  }
  
  .mousePointer {
    cursor: pointer;
  }
  
  .expiredRow {
    background-color: rgb(238, 77, 77);
  }
  
  .warningRow {
    background-color: rgb(240, 240, 117);
  }
  
  h1, h2 {
    font-family: var(--headingFont);
    font-weight: 900;
    margin: 0 0 4rem 0;
    text-align: center;
    color: var(--gris2);
  }
  h1 span {
    font-weight: 400;
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.4rem;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  a {
    text-decoration: none;
    color: var(--gris2);
  }
  
  /** BOTONES **/
  
  /* .navbarItems button {
    background: none;
    color: red;
  } 
  
  .navbarItems button:hover {
    color: #2FD9FF;
  }
   */
   
  
  /* .btn {
    margin-top: 2rem;
    font-family: var(--headingFont);
    padding: 1.5rem;
    font-size: 1.4;
    font-weight: 400;
    border: none;
    transition: background-color .3s ease;
  }
  .btn:hover {
    cursor: pointer;
  }
  .btn-primario {
    background-color: black;
    color: var(--blanco);
    font-family: system;
    font-size: 1.5em;
  }
  .btn-primario:hover {
    background:#00C7FF;
    color: black;
  }
  .btn-secundario {
    background-color: var(--gris1);
  }
  .btn-block {
    display: block;
    width: 100%;
  }
  .btn-submit:hover {
    background-color: var(--gris2);
  }
  .btn-blank {
    margin: 0;
    padding: 0;
    display: block;
    background-color: transparent;
  } */
  
  .crudBtnsPosition button {
    border: none; 
  }
  
  .crudBtnsPosition button:hover {
    background-color: #00B3E5;
    color: white;
  }
  
  .closeSesion {
    margin-right: 15px;
    border-radius: 25px;
    background-color: #2B2B2B;
    border: none;
    color: white;
    font-size: 2em;
  }
  
  .closeSesion:hover {
    color: black;
  }
  
  .closeSesion:hover {
    background-color: #00C7FF;
  }
  
  /*--- home ---*/
  .home {
    background-image: url("./assets/images/fondoHome.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    padding-top: -160px;
  }
  
  .homeTitle {
    padding-top: 10%;
    color: #00C7FF;
    font-size: 8em;
    font-family: Impact;
    letter-spacing: 2px;
  }
  
  .homeSubT {
    color: white;
    font-size: 2em;
    font-family: system;
  }

  
  .homeSubT {
    color: white;
    font-size: 4em;
    font-family: sans-serif;
  }

  .contenedor-app {
      display: flex;
      min-height: 100vh;
  }

  aside {
      background-color: var(--blanco);
      flex: 0 0 300px;
  }
  .seccion-principal {
      flex: 1;
  }

  
/* BARRA LATERAL */
aside  {
  padding: 3rem;
}

.formulario-nuevo-proyecto {
  margin-top: 6rem;
}
.formulario-nuevo-proyecto .input-text {
  border-bottom: 1px solid var(--gris2);
  border-radius: 0;
  background-color: var(--gris1);
}
.formulario-nuevo-proyecto .input-text::placeholder{
  color: var(--gris2);
}
.proyectos {
  margin-top: 10rem;
}
.listado-proyectos li {
  margin-bottom: 2rem;
}
.listado-proyectos li a {
  font-size: 1.4rem;
  color: var(--gris2);
  line-height: 1.5;
  font-weight: 300;
}
.app-header {
  display: flex;
  height: 45px;
  justify-content: space-between;
  background-color: #1D1D1D;
  align-items: center;
}
.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #2FD9FF;
  margin-left: 25px;
  font-family: system;
  border: none;
  background: transparent;
  font-size: 2em;
}
.welcome span {
  font-weight: 900;
}
.nav-principal a {
  color: var(--blanco);
  text-decoration: none;
}
.nav-principal a:first-of-type {
  margin-right: 2rem;
}

/** Formulario crear y editar tareas **/
.formulario {
  background-color: var(--gris3);
  padding: 4rem;
}
.formulario form {
  max-width: 600px;
  margin: 0 auto;
}
.contenedor-input {
  display: flex;
}
.contenedor-input label {
  margin-bottom: 1rem;
  color: var(--blanco);
}
.input-text {
  padding: 1rem;
  border: none;
  border-radius: .5rem;
  flex: 1;
  width: 100%;
}

/** TAREAS LISTADO **/
.contenedor-tareas {
  padding: 4rem;
}



.listado-tareas {
  max-width: 600px;
  margin: 0 auto;
}

.tarea {
  display: flex;
  justify-content: space-between;
  background-color: var(--blanco);
  padding: .5rem 2rem;
  align-items: center;
  margin-bottom: 1rem;
  border-radius: .5rem;

}
.sombra {
  -webkit-box-shadow: 0px 4px 10px -3px rgba(117,117,117,1);
  -moz-box-shadow: 0px 4px 10px -3px rgba(117,117,117,1);
  box-shadow: 0px 4px 10px -3px rgba(117,117,117,1);
}
.sombra-dark {
  -webkit-box-shadow: 0px 6px 11px -8px rgba(0,0,0,0.9);
  -moz-box-shadow: 0px 6px 11px -8px rgba(0,0,0,0.9);
  box-shadow: 0px 6px 11px -8px rgba(0,0,0,0.9);
}
.tarea .acciones .btn:first-of-type {
  margin-right: 1rem;
}
.tarea p {
  font-size: 1.6rem;
  flex: 1;
  padding-right: 2rem;
}
.tarea .btn {
  padding: .8rem;
  font-size:1.4rem;
  border-radius: 0;
  margin: 0;
}

.estado {
  margin-right: 1rem;
}
.estado button, 
.mensaje {
  font-weight: 900;
  font-family: var(--textFont);
  font-size: 1rem;
  padding: .5rem;
  border-radius: .5rem;
  cursor: pointer;
  border: none;
}
.mensaje {
  text-align: center;
  font-size: 1.6rem;
  border-radius: 0;
}
.mensaje:hover {
  cursor: default;
}
.completo,
.correcto {
  background-color: rgba(154, 252, 154, .3);
  color: var(--gris3);
}
.incompleto{
  background-color: rgba(255, 178, 178, .4);
  color: var(--gris3);
}
.error {
  max-width: 600px;
  margin: 2rem auto 0 auto;
  background-color: rgb(170, 0, 0);
  color: var(--blanco);
}



.tarea-enter,
.proyecto-enter {
  opacity: 0;
}
.tarea-enter-active,
.proyecto-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.tarea-exit,
.proyecto-exit {
  opacity: 1;
}
.tarea-exit-active,
.proyecto-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.loginGreet {
  color: white;
  z-index: 100;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 250px;
  font-size: 6em;
  font-family: Impact;
  letter-spacing: 2px;
}

.form-usuario {
    background-image: url("./assets/images/fondoLogin.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    min-height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
}

.contenedor-form {
    padding: 5rem 3rem;
    max-width: 500px;
    width: 95%;
    background-color: var(--blanco);
    border-radius: 1rem;
}

.campo-form {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
    font-size: 2em;
}
.campo-form:last-of-type {
  margin: 0;
}
.campo-form label {
  flex: 0 0 100px;
  font-family: var(--textFont);
}
.campo-form input[type="password"],
.campo-form input[type="email"], 
.campo-form input[type="text"] {
  border: 1px solid #e1e1e1;
  padding: 1rem;
  flex: 1;
}

.loginBtn {
  font-size: 1em;
  background-color: rgb(0, 114, 167);
  font-weight: bold;
  margin: auto;
  width: 100px;
  color: white;
}

.loginBtn:hover {
  font-size: 1em;
  background-color: #2AD9FF;
}

.enlace-cuenta {
  margin-top: 2rem;
  display: block;
  opacity: .7;
}


/*Navbar*/
#mainLogo{
  margin-left: 5%;
}

#mainNavbar {
  background: black;
  text-align: center;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#mainNavbar ul li {
  display: inline;
}

#mainNavbar ul li button {
  border: none;
  margin: 5px;
  color: white;
  font-size: 2em;
}

#mainNavbar ul li button:hover {
  box-shadow: none;
  color: #2FD9FF;
}

/* .academiesTable {
  height: 435px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 200px;
} */





.loginGreet {
  padding-top: 5%;
  font-size: 12em;
}

.emailInput {
  height: 50px;
  width: 200px;
  border-radius: 5px;
  margin-right: 1%;
}

#downloadSendBtns {
  width: 800px;
}

.emailInput {
  font-size: 2rem !important;
  width: 200px !important;
}

#sendEmail {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#downloadEmail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2%;
}

.statisticsTable,
#academiesContainer {
  background-color: #DFDFDF !important;
  height: 635px;
  overflow-y: scroll;
  display: block;
  width: 100%;
  margin-bottom: 15px;
}

.statisticsTable {
  margin-bottom: 200px;
}

.MuiPopover-root .MuiPaper-root {
  background-color: black !important;
  color: white !important;
}

.BBStudentsSection{
  margin-top: 40px !important;
}

.BBStudentsSectionTables{
  margin-top: 40px !important;
}