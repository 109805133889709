
#examCrudMainContainer {
    background-color: #1D1D1D;
    border-radius: 5%;
    width: 400px;
    height: 260px;
    margin-bottom: 15px;
    color: white;
    padding: 1%;
}

#newExamContainer {
    margin: 7px;
}

.crudMainTitle {
    font-size: 3em;
    margin-bottom: 5px;
}

.examTitleH1 {
    font-size: 6em !important;
    margin-top: 2%;
    margin-bottom: 4%;
    color: black;
}

.deleteDetailBtn,
.loadStudentsBtn {
    background-color: #00C7FF;
    margin: auto;
    border-radius: 9999px;
    border: 1px solid black; 
    margin-top: 15px;
    margin-bottom: 15px;
}

#loadStudentsBtnWords {
    font-weight: bold;
    font-size: 1.5em;
}

#loadStudentsBtnWords:hover {
    color: white;
}

.deleteDetailBtn{
    font-size: 18px;
    padding: 10px;
    font-weight: bold;
    transition: all 300ms;
}

.deleteDetailBtn:hover{
    color: white;
    background-color: black;
}

.examDetailsMainContainer {
    background-color: #1D1D1D;
    border-radius: 5%;
    height: 410px;
    margin-bottom: 15px;
    color: white;
    padding: 1%;
}

#examDetailInnerContainer {
    margin: 7px;
}

.examDetailsCrud {
    margin-top: 3% !important;
}

#cruds {
    margin-top: 5%;
}

.filesBtns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #1D1D1D;
    height: 150px;
    margin-top: 3%;
    margin-left: 0%;
    margin-right: 0%;
}

.ccBtns {
    height: 50px;
    width: 400px;
    
    min-width: 400px !important;
    min-height: 50px !important;
    color: white !important;
    font-size: 1.7em !important;
    border: 2px solid #00C7FF !important;
    border-radius: 9999px !important;
    transition: all 1s !important;
}

.ccBtns:hover {
    background-color: #00C7FF !important;
    color: black !important;
    font-weight: bold !important;
}

.examTotalsTable {
    width: 600px !important;
    margin-top: -1% !important;
}

.examTable {
    width: 600px !important;
    margin: 40px 25% 0px 25% !important;
}

.stateSelection {
    margin-bottom: 5px;
}

.form-label {
    color: white;
}

#beltsColors {
    color: black;
}
/* .examTitle {
    font-size: 2.7em !important;
    margin-top: 1% !important;
    margin-bottom: 2% !important;
} */

.examAcademyTable {
    padding-right: 3% !important;
}

.examCrud {
    margin-top: 3% !important;
}

.lBox {
    margin-left: 3% !important;
}

