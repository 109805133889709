
.cannonMainContainer {
    min-height: 100%;
    max-width: 100vw;
/*     background-color: #DFDFDF;*/
    background: #D9D9D9;
}

.inputs {
    background-color: #DFDFDF;
}

label {
    font-weight: bold;
}

.inputFonts{
font-size: 1em;
}

.btnFont {
    font-size: 1em;
    border-radius: 9999px;
    color: black;
    font-weight: bold;
    background-color: #00C7FF;
}

.btnFont:hover {
    background-color: #00a0cc;
    color: black;
}

.studentsSelect {
    margin-right: 5px;
}

.marks {
    height: 40px;
}

.paid {
    background-color: rgb(86, 206, 86) !important;
}

/* @media (max-width: 480px) {
    
    .cannonTitle {
        width: 330px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .cannonsTotalsTitle {
        width: 330px !important;
    }

    .cannonTotals{
        width: 340px;
        margin-bottom: 15px;
    }
    
    .cannonTable {
        max-width: 100vw;
        margin-bottom: 15px;
    }

    .cannonScroll {
        margin: auto;
    }

    #cannonScrollX {
        overflow-x: visible;

    }
    .cannonCrud {
        width: 330px !important;
    }
}

@media (max-width: 765px) {

    .cannonTitle {
        padding-top: 7%;
    }

    .academiesTable {
        margin: auto;
        height: 250px;
    }

    .cannonTable {
    }

    .cannonScroll{
        padding-left: 7%;
        background-color: #DFDFDF;
    }

    .expirationDate {
        margin: 5%;
    }

    .inputs {
        margin-top: 7%;
        margin-left: 5%;
        margin-right: 5%;
    }

    .crudBtnsPosition {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .btnCrear {
        margin: 5px;
    }

    .btnEliminar {
        margin: 5px;
    }

    .btnActualizar {
        margin: 5px;
    }

    .cannonsTotalsTitle {
        font-size: 1.5em;
        margin-top: 35px;
        margin-bottom: 15px;
    }

}

@media (min-width: 766px) and (max-width: 1025px) {

    .cannonTitle {
        padding-top: 4.5%;
    }

    .cannonEditionTitle {
        font-size: 3em;
    }

    .academiesTable {
        padding-left: 15%;
        height: 300px;
        width: 100%;
    }

    .cannonTable {
    }

    .cannonScroll {
        height: 230px;
        overflow-y: scroll;
        display: block;
        width: 100%;
        background-color: #DFDFDF;
      }

    .expirationDate {
        margin-bottom: 5%;
        margin-right: 15%;
        margin-top: 5%;
    }

    .inputs {
        background-color: #c2c2c2;
        margin-top: 2.5%;
        padding-top: 2.5%;
        padding-left: 25%;
        padding-right: 25%;
        padding-bottom: 2.5%;
    }

    .crudBtnsPosition {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .btnCrear {
        margin: 5px;
    }

    .btnEliminar {
        margin: 5px;
    }

    .btnActualizar {
        margin: 5px;
    }
} */





    .cannonTitle {
        padding-top: 2%;
        padding-bottom: 2%;
    }

    .cannonEditionTitle {
        font-size: 6em;
        color: black; 
    }

    .cannonTable {
        width: 450px;
    }

    #cannonScrollX {
        overflow-x: hidden;
    }

    .cannonScroll {
        background-color: #DFDFDF;
        height: 350px;
        overflow-y: scroll;
        display: block;
        margin-bottom: 15px;
    }

    #alumnosTable {
        width: 500px;
    }

    .expirationDate {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .inputs {
        background-color: #c2c2c2;
        /* margin-top: 2.5%;
        padding-top: 2.5%;
        padding-left: 35%;
        padding-right: 35%;
        padding-bottom: 2.5%; */
    }

    .crudBtnsPosition {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .btnCrear {
        background-color: #00C7FF;
        border-radius: 9999px;
        border: 1px solid black; 
        margin-left: 5px;
        width: 70%;
        height: 100%;
    }

    .btnCrearGrid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #agregarABtn {
        background-color: #00C7FF;
        border-radius: 9999px;
        border: 1px solid black; 
        margin-left: 5px;
        width: 70%;
        height: 100%;
        color: black;
        font-weight: bold;
        font-size: 1.8em;
    }

    #agregarABtn:hover {
        color: white;
    }

    .btnCrearExamen {
        margin: 5px;
    }

    .btnEliminar {
        margin: 5px;
    }

    .btnActualizar {
        margin: 5px;
    }

    .cannonTotals {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 4%;
        margin-left: auto !important;
        margin-right: auto !important;
        color: white;
        background-color: #1D1D1D;
        border-radius: 15px 15px;
        box-shadow: 5px 10px 8px 10px #888888;
    }

    /* #cannonTotalsMainContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #1D1D1D;
        border-radius: 15px 15px 0% 0% ;
        width: 60%;
    } */

    .cannonsTotalsTitle {
        width: 100%;
        font-size: 5em;
        color: white;
        margin-top: 15px;
    }

    #cannonsTotalsHead {
        background-color: rgb(42, 156, 223);
    }

    .cannonCrud {
        background-color: #1D1D1D;
        height: 330px;
        color: white;
        padding: 1%;
        margin-top: 25px;
    }

    .expFontTotals {
        font-size: 2em;
    }
   
    .selFontTotals {
        font-size: 2em;
        color: black
    }

    #cannonform {
        height: 100%;
        width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .cannonformItem {
        font-size: 2em;
    }

    .cannonTableBody {
        font-size: 1.7em;
    }

    .beforeCannonCrud {
        height: 10px;
    }

    .canoonAcademiesTable {
        margin-right: 3% !important;
    }


    



