.tableLoading {
    text-align: center;
    margin: 0px auto;
    margin-left: 40%;
    margin-top: 50%;
}


.generatingCertif {
    outline: none !important;
    border: 4px solid #00C7FF !important;
    border-radius: 50% !important;
    width: 36px !important;
    height: 36px !important;
    min-width: 36px !important;
    min-height: 36px !important;
    border-left-color: transparent !important;
    overflow: hidden !important;
    color: transparent !important;
    cursor: default !important;
    
    animation: spin 1s linear infinite;
    animation-delay: 0.75s;
}

.generatingCertif:hover {
    background-color: transparent!important;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}