
.charterTotals {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    color: white;
    background-color: #1D1D1D;
    border-radius: 15px 15px;
    box-shadow: 5px 10px 8px 10px #888888;
}

.scAcademiesTable {
    margin-right: 3% !important;
}

.charterCrud {
    background-color: #1D1D1D;
    width: 400px;
    height: 330px;
    color: white;
    padding: 1%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    margin-top: 25px;
}


@media (max-width: 1399px) {

    .cannonH1Title {
        font-size: 6em;
        margin-top: 3%;
        color: black;
    }
  
}

@media (min-width: 1400px) {

#charterCrudMainContainer {
    background-color: #1D1D1D;
    width: 400px;
    height: 330px;
    margin-bottom: 15px;
    color: white;
    padding: 1%;
}

.inputs {
    background-color: #D9D9D9;
}

.CharterExpirationDate {
    width: 50%;
}

.chartersScroll {
    height: 300px;
    overflow-y: scroll;
    display: block;
    width: 50%;
    border: 1px solid rgb(194, 194, 194);
}

.CharterYearSelection {
    width: 50%;
}

#ChTotalsMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #1D1D1D;
    border-radius: 15px 15px 0% 0% ;
    width: 60%;
}

.CharterTotalsH1 {
    width: 100%;
    font-size: 5em;
    color: white;
}

.charterTable {
    width: 84%;
    margin-bottom: 5%;
}

.charterTitle {
    margin-top: 3% !important;
    margin-bottom: 1%;
}

.CharterTotalsYear {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.charterSelectYear {
    width: 50%;
}

.charterTotalsScroll {
    background-color: #DFDFDF;
    height: 350px;
    overflow-y: scroll;
    display: block;
    margin-bottom: 15px;
}

.CharterTotalsYear {
    font-size: 2em;
    color: white
}

#chartersTotalsHead {
    background-color: rgb(42, 156, 223);
}

.charterExpirationDate {
    margin-bottom: 15px;
}

.cannonsTotalsTitle {
    width: 600px;
}

.cannonH1Title {
    font-size: 6em;
    color: black;
}

}
